<template>
    <section class="reset">

        <div class="container">
            <aside>
                <img src="../../assets/images/logos/logo.png" alt="Shory Logo">
            </aside>
            
            <form>
                <h1 slot="heading">استعادة كلمة المرور</h1>
                <input type="password" v-model="newPassword" name="password" placeholder="كلمة المرور" required autocomplete="off">
                <input type="password" v-model="confirmNewPassword" name="password" placeholder="تأكيد كلمة المرور" required autocomplete="off">
                <button @click="register($event)">استعادة</button>
            </form>
        </div>
    </section>
</template>

<script>

export default {
    name: 'reset',
    data() { 
        return {
            newPassword: '',
            confirmNewPassword: ''
        }
    },
    methods: {
        register: function(e) {
            e.preventDefault();
            let content = e.target.textContent
            e.target.textContent = ''
            e.target.classList.add('loading')

            if(this.newPassword === this.confirmNewPassword) {
                setTimeout(() => {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                }, 3000)

            } else {
                setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                }, 2000)

            }
        }
    }
}
</script>

<style lang="sass" scoped>

.reset
    width: 100%
    height: 100%
    position: relative
    
    .container
        width: 95%
        height: 100%
        max-width: 745px
        max-height: 545px
        border-radius: 20px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: #fff
        box-shadow: 15px 15px 15px #ddd
        display: flex
        align-items: center
        overflow: hidden

        @media screen and (max-width: 768px)
            max-width: 600px

        @media screen and (max-width: 621px)
            max-height: auto
            flex-wrap: wrap

        aside
            width: 30%
            min-width: 230px
            height: 100%
            background-image: url('../../assets/images/stars.svg')
            background-size: cover
            display: flex
            align-items: center
            justify-content: center
            pointer-sevents: none

            @media screen and (max-width: 621px)
                width: 100%
                height: 170px

            img
                max-width: 90%

        .app-form
            width: 70%
            height: 100%

            @media screen and (max-width: 621px)
                width: 100%
                height: calc(100% - 170px)

        form
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            
            @media screen and (max-width: 621px)
                width: 100%
                height: calc(100% - 170px)

            h1
                margin-bottom: 15px

            input:not([type="submit"])
                border: 1px solid #eee
                box-shadow: 2px 2px 5px #eee
                font-family: inherit
                outline: 0
                border-radius: 25px
                padding: 4px 13px
                font-size: 1.1em
                font-weight: 100
                margin: 5px 0
                width: 240px

                &::placeholder
                    color: #ccc
                    font-size: 14px
            
            button
                background-color: #000
                color: #fff
                border-radius: 25px
                border: 0
                font-family: inherit
                cursor: pointer
                font-size: 1.1em
                padding: 4px 13px
                margin-top: 15px
                width: 240px
                height: 40px
                outline: none
                transition: all .3s ease
                position: relative

                &::before
                    content: ''
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%)
                    width: 40px
                    height: 40px
                    background-color: #999
                    border-radius: 50%
                    transition: all .3s ease
                    z-index: -1
                    visibility: hidden

                &::after
                    content: url('../../assets/images/thumb.svg')
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%) scale(.5)
                    padding: 10px 7px 10px 10px
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    background-color: #000
                    transition: back .3s ease
                    visibility: hidden

                &.loading
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    pointer-events: none

                    &::before
                        visibility: visible
                        animation: scale-up 1s linear infinite

                        @keyframes scale-up
                            0%
                                transform: translate(-50%, -50%) scale(1)
                                opacity: 1
                            100%
                                transform: translate(-50%, -50%) scale(2)
                                opacity: 0

                &.success
                    width: 60px
                    height: 60px
                    border-radius: 50%
                    padding: 20px
                    background-color: #2ecc71
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #2ecc71
                        transform: translate(-50%, -50%) scale(1)

                &.failed
                    width: 60px
                    height: 60px
                    border-radius: 50%
                    background-color: #e74c3c
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #e74c3c
                        transform: translate(-50%, -50%) scale(1) rotate(-180deg)

</style>

